@import "variables";

.icon {
  background: $black;
  height: 50px;
  width: 50px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 90%;
}

.icon-diversio-logo {
  mask-image: url("/images/diversio-logo.png");
}

.icon-home {
  mask-image: url("/images/home.svg");
}

.icon-improve {
  mask-image: url("/images/improve.svg");
}

.icon-analyze {
  mask-image: url("/images/analyze.svg");
}

.icon-road-map {
  mask-image: url("/images/road-map.svg");
}

.icon-portfolio {
  mask-image: url("/images/portfolio.svg");
}

.icon-portco {
  mask-image: url("/images/portfolio.svg");
}

.icon-connect {
  mask-image: url("/images/connect.svg");
}

.icon-communication {
  mask-image: url("/images/communication.svg");
}

.icon-signout {
  mask-image: url("/images/signout.svg");
}

.icon-three-dots {
  mask-image: url("/images/three-dots.svg");
}

.icon-employee-comment {
  mask-image: url("/images/employee-comment.svg");
}

.icon-survey {
  mask-image: url("/images/survey.svg");
}

.icon-star {
  mask-image: url("/images/star.svg");
}

.icon-cross {
  mask-image: url("/images/cross.svg");
}

.icon-add {
  mask-image: url("/images/cross.svg");
  transform: rotate(45deg);
}

.icon-caret {
  mask-image: url("/images/caret.svg");
}
.icon-iln-impact {
  mask-image: url("/images/ILN/impact_icon.svg")
}

.icon-iln-download {
  background: $iln-dark;
  height: 24px;
  width: 24px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 90%;
  mask-image: url("/images/ILN/iln-download.svg")
}

.icon-iln-search {
  height: 50px;
  width: 50px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/images/ILN/searchButton.svg");
}

.icon-iln-plus {
  mask-image: url("/images/ILN/plus.svg");
  background: $iln-primary;
  height: 24px;
  width: 24px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.icon-iln-minus {
  mask-image: url("/images/ILN/minus.svg");
  background: $iln-primary;
  height: 24px;
  width: 24px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.icon-iln-calendar {
  mask-image: url("/images/ILN/calendar-event.svg");
  background: $iln-dark;
  height: 16px;
  width: 16px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.icon-iln-description {
  mask-image: url("/images/ILN/calendar-desc.svg");
  background: $iln-dark;
  height: 16px;
  width: 16px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.icon-iln-event-time {
  mask-image: url("/images/ILN/calendar-icon.svg");
  background: $iln-dark;
  height: 16px;
  width: 16px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.icon-iln-calendar-box {
  mask-image: url("/images/ILN/calendar-box.svg");
  height: 16px;
  width: 16px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}

.icon-refresh {
  mask-image: url("/images/refresh.svg");
  height: 16px;
  width: 16px;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: 100%;
}
